.slot_schedule {
    height: 100%;
    left: 4px;
    right: 4px;
    border: 0;
    border-radius: 4px;
    position: absolute;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.slot_schedule:hover {
    transform: scale(1.02);
}

.main svg {
    width: 14px;
    color: var(--color-text-primary-inverted);
}

.selected {
    background-color: #000000;
    position: absolute;
    right: -3px;
    top: -9px;
    width: 20px;
    height: 20px;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selected svg {
    width: 20px;
}